.home-container {
    text-align: center;
    .header {
        color: white;
        margin-top: 50px;
        margin-bottom: 20px;
        max-width: 550px;
    }

    .sub-heading {
        font-size: 16px;
        margin-bottom: 30px;
        max-width: 350px;
    }

    .download-btn {
        height: 48px;
        padding: 0 24px;
        background-color: #1DA1F2;
        color: white;
        font-weight: bold;
        margin-bottom: 30px;
    }

    .demo-video-wrapper {
        margin-bottom: 60px;
        overflow: hidden;
        .demo-video {
            border: 1px solid rgb(47, 51, 54);
        }
    }

    .laps-copyright {
        margin-bottom: 22px
    }
}

@media (max-width: 330px) {
    .demo-video-wrapper {
        max-height: 504px;
        .demo-video {
            width: 260px;
            // border: 1px solid rgb(47, 51, 54);
        }
    }
}

@media (min-width: 576px) {
    .header {
        font-size: 50px;
    }
}

.info-modal {
    text-align: center;
    max-width: 578px;
    background-color: black;
    padding: 20px 40px;
    outline: 0;
    border: 1px solid rgb(47, 51, 54);
    margin: 30px;

    .info-modal-header {
        font-weight: bold;
    }

    .info-modal-paragraph {
        margin-top: 10px;
    }

    .twitter-discord {
        margin-bottom: 20px;
        .twitter-icon {
            margin-right: 5px;
            width: 48px;
            height: 48px;
        }
    
        .discord-icon {
            margin-left: 5px;
            width: 48px;
            height: 48px;
        }
    }

    .modal-continue-btn {
        background-color: white;
        color: black;
        font-weight: bold;
    }
}