.navbar-container {
    font-size: 16px;
    height: 62px;
    background-color: black;

    .beta-txt {
        position: absolute;
        font-size: 16px;
        font-weight: normal;
        top: 29px;
        left: 15px;
    }

    .navbar-toggler:focus,
    .navbar-toggler:active,
    .navbar-toggler-icon:focus {
        outline: none;
        box-shadow: none;
    }

    .laps-btn {
        background-color: transparent;
        border: none;
    }

    .laps-icon-img {
        width: 30px;
        height: 30px;
        margin-bottom: 5px;
    }

    .nav-txt {
        margin-right: 25px;
        color: white;
        text-decoration: none;
        font-weight: 500;
    }

    .nav-txt-last {
        color: white;
        text-decoration: none;
        font-weight: 500;
    }
}

.navbar > .custom-container {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}

.navbar-collapse{
    background-color: black;
}  