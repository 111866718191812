@import "~bootstrap/scss/bootstrap";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.btn:focus {
  box-shadow: none;
}

.custom-container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .custom-container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .custom-container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .custom-container {
    max-width: 1100px;
  }
}
